import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import {
  FORGOT_PASSWORD,
  SET_PASSWORD,
  SIGN_UP,
} from '../../../constants/routes';
import { setLoading, setMessage } from '../../../store/settings';

import { getTokens, verifyToken } from '../../../helpers/tokens';
import { getEnvUserPoolClients } from '../../../constants/clients';
import {
  getCurrentSession,
  adminLogIn,
  logInAll,
  logOutAll,
} from '../../../helpers/auth';
import CustomButton from '../../../components/CustomButton';
import CustomCardContent from '../../../components/CustomCardContent';
import CustomTextField from '../../../components/CustomTextField';
import CustomListItemTextField from '../../../components/CustomListItemTextField';
import GoogleButton from '../../../components/GoogleButton';
import TextWithLink from '../../../components/TextWithLink';
import MessageBox from '../../../components/MessageBox';

const ADMIN_REDIRECT_SIGNIN_URL =
  process.env.REACT_APP_ADMIN_REDIRECT_SIGNIN_URL;

function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const client = useSelector((state) => state.client);

  const [idToken, setIdToken] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  const redirectToApplication = (refreshToken) => {
    window.location.assign(`${client.signInUrl}?refreshToken=${refreshToken}`);
  };

  useEffect(() => {
    const isSuper = idToken?.['custom:SuperUser'] === 'true';
    if (client.clientId === 'admin' && !idToken === null && !isSuper) {
      dispatch(
        setMessage({
          type: 'error',
          text: 'User must be an Orchestrate Admin to access the Admin Dashboard',
        })
      );
    } else {
      dispatch(setMessage({ type: '', text: '' }));
    }
  }, [idToken, client, dispatch]);

  useEffect(() => {
    const findUser = async () => {
      dispatch(setLoading(true));
      try {
        let _idToken = null;
        const session = await getCurrentSession(client.clientId);
        if (session) {
          _idToken = await verifyToken(session.idToken, 'id', client.clientId);
        }
        setIdToken(_idToken ? { ..._idToken } : null);
      } catch (error) {
        console.warn(error);
        setIdToken(null);
      } finally {
        dispatch(setLoading(false));
      }
    };
    if (client.clientId) {
      findUser();
    }
  }, [client.clientId, dispatch]);

  const handleAdminLogin = async () => {
    const result = await adminLogIn(email, password);
    const session = await getCurrentSession(client.clientId);

    let _idToken = null;
    if (session) {
      _idToken = await verifyToken(session.idToken, 'id', client.clientId);
    }
    const isSuper = _idToken?.['custom:SuperUser'] === 'true';

    if (!isSuper) {
      setIdToken(_idToken);
      return;
    }

    const url = new URL(ADMIN_REDIRECT_SIGNIN_URL);
    for (const [key, value] of Object.entries(result)) {
      url.searchParams.append(key, value.refreshToken);
    }
    window.location.assign(url);
  };

  const handleLogIn = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));

    try {
      // Log in to Admin Dashboard
      if (client.clientId === 'admin') {
        handleAdminLogin();
        return;
      }

      // Log in to all other apps
      const result = await logInAll(email, password, client.clientId);
      if ('refreshToken' in result) {
        redirectToApplication(result.refreshToken);
      } else if (result.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(push(`${SET_PASSWORD}?client_id=${client.clientId}`, result));
      } else {
        dispatch(setMessage({ type: 'error', text: 'Error logging in' }));
      }
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleContinueToApplication = (e) => {
    e.preventDefault();
    if (client.clientId === 'admin') {
      let refreshTokens = {};
      for (const clientId in getEnvUserPoolClients()) {
        const { refreshToken } = getTokens(clientId);
        refreshTokens[clientId] = { refreshToken };
      }
      const url = new URL(ADMIN_REDIRECT_SIGNIN_URL);
      for (const [key, value] of Object.entries(refreshTokens)) {
        url.searchParams.append(key, value.refreshToken);
      }
      window.location.assign(url);
    } else {
      const { refreshToken } = getTokens(client.clientId);
      redirectToApplication(refreshToken);
    }
  };

  const handleLogOut = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));

    try {
      await logOutAll();
      setIdToken(null);
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const isSuper = idToken?.['custom:SuperUser'] === 'true';

  if (idToken?.email) {
    return (
      <CustomCardContent title='Log in with Orchestrate'>
        <Typography
          component='div'
          variant='h6'
          color={(theme) => theme.palette.text.black}
          gutterBottom
          align='left'
          sx={{ ml: 2 }}
        >
          logged in as{' '}
          <Box fontWeight='800' display='inline'>
            {idToken?.email}
          </Box>
        </Typography>
        <MessageBox />
        <ListItem sx={{ flexDirection: 'column', alignItems: 'end' }}>
          <CustomButton
            onClick={handleContinueToApplication}
            disabled={client.clientId === 'admin' && !isSuper}
          >
            Continue to {client.domainName}
          </CustomButton>
          <TextWithLink linkText='Log out' onClick={handleLogOut} />
        </ListItem>
      </CustomCardContent>
    );
  } else {
    return (
      <CustomCardContent title='Log in with Orchestrate'>
        {client.domainName && (
          <Typography
            component='div'
            variant='h6'
            color={(theme) => theme.palette.text.black}
            gutterBottom
            align='left'
            sx={{ ml: 2 }}
          >
            to continue to{' '}
            <Box fontWeight='800' display='inline'>
              {client.domainName}
            </Box>
          </Typography>
        )}
        <MessageBox />
        <form onSubmit={handleLogIn}>
          <List>
            <CustomListItemTextField
              id='login-email'
              label='Email'
              value={email}
              autoComplete='current-email'
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              icon={
                <InputAdornment position='start'>
                  <EmailIcon />
                </InputAdornment>
              }
            />
            <ListItem sx={{ flexDirection: 'column', alignItems: 'end' }}>
              <CustomTextField
                id='login-password'
                label='Password'
                value={password}
                autoComplete='current-password'
                onChange={(e) => setPassword(e.target.value)}
                icon={
                  <InputAdornment
                    position='start'
                    onClick={() => setHidePassword((h) => !h)}
                  >
                    {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </InputAdornment>
                }
                type={hidePassword ? 'password' : 'text'}
              />
              {client.clientId !== 'admin' && (
                <TextWithLink
                  linkText='Forgot Password?'
                  onClick={() =>
                    navigate(`/${FORGOT_PASSWORD}?client_id=${client.clientId}`)
                  }
                />
              )}
            </ListItem>
            <ListItem sx={{ flexDirection: 'column', alignItems: 'end' }}>
              <CustomButton>Log In</CustomButton>
              {client.clientId !== 'admin' && (
                <TextWithLink
                  text='Need an account?'
                  linkText='Sign Up'
                  onClick={() =>
                    navigate(`/${SIGN_UP}?client_id=${client.clientId}`)
                  }
                />
              )}
            </ListItem>
            <GoogleButton action='log in' />
          </List>
        </form>
      </CustomCardContent>
    );
  }
}

export default LogIn;
