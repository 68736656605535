import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { useNavigate } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { signUp } from '../../../helpers/cognito';
import { setLoading, setMessage } from '../../../store/settings';
import { CONFIRM_SIGN_UP, LOG_IN } from '../../../constants/routes';
import CustomButton from '../../../components/CustomButton';
import CustomCardContent from '../../../components/CustomCardContent';
import CustomTextField from '../../../components/CustomTextField';
import CustomListItemTextField from '../../../components/CustomListItemTextField';
import MessageBox from '../../../components/MessageBox';
import GoogleButton from '../../../components/GoogleButton';
import TextWithLink from '../../../components/TextWithLink';

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useSelector((state) => state.client);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));
    const username = email.toLowerCase();

    try {
      await signUp(username, password, name, client);
      dispatch(
        push(
          `/${CONFIRM_SIGN_UP}?client_id=${client.clientId}&username=${username}`
        )
      );
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <CustomCardContent title='Sign Up with Orchestrate'>
      <Typography
        component='div'
        variant='h6'
        color={(theme) => theme.palette.text.black}
        gutterBottom
        align='left'
        sx={{ ml: 2 }}
      >
        to create an account for{' '}
        <Box fontWeight='800' display='inline'>
          {client.domainName}
        </Box>
      </Typography>
      <MessageBox />
      <form onSubmit={handleSubmit}>
        <List>
          <CustomListItemTextField
            id='signup-name'
            label='Name'
            value={name}
            autoComplete='current-name'
            onChange={(e) => setName(e.target.value)}
            icon={
              <InputAdornment position='start'>
                <AccountCircleIcon />
              </InputAdornment>
            }
          />
          <CustomListItemTextField
            id='signup-email'
            label='Email'
            value={email}
            autoComplete='current-email'
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            icon={
              <InputAdornment position='start'>
                <EmailIcon />
              </InputAdornment>
            }
          />
          <ListItem sx={{ flexDirection: 'column', alignItems: 'end' }}>
            <CustomTextField
              id='signup-password'
              label='Password'
              value={password}
              autoComplete='current-password'
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              icon={
                <InputAdornment position='start'>
                  <LockIcon />
                </InputAdornment>
              }
            />
          </ListItem>
          <ListItem sx={{ flexDirection: 'column', alignItems: 'end' }}>
            <CustomButton onClick={handleSubmit}>Sign Up</CustomButton>
            <TextWithLink
              text='Already have an account?'
              linkText='Log In'
              onClick={() =>
                navigate(`/${LOG_IN}?client_id=${client.clientId}`)
              }
            />
          </ListItem>
          <GoogleButton action='sign up' />
        </List>
      </form>
    </CustomCardContent>
  );
}

export default SignUp;
