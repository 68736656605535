import React from 'react';

import { useTheme } from '@emotion/react';
import styled from '@mui/material/styles/styled';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Div = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
  };
});

function TextWithLink(props) {
  const theme = useTheme();
  const { text, linkText, onClick } = props;

  return (
    <Div>
      <Typography
        variant='body2'
        sx={{ mr: '4px', color: theme.palette.text.grey }}
      >
        {text}
      </Typography>
      <Link underline='hover' onClick={onClick} variant='body2'>
        {linkText}
      </Link>
    </Div>
  );
}

export default TextWithLink;
