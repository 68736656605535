import React from 'react';

import Link from '@mui/material/Link';

function CustomLink(props) {
  const { children, onClick } = props;
  return (
    <Link
      variant='body2'
      underline={onClick ? 'hover' : 'none'}
      onClick={onClick}
      color={(theme) => theme.palette.common.white}
    >
      {children}
    </Link>
  );
}

export default CustomLink;
