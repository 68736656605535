import React from 'react';

import Button from '@mui/material/Button';

function CustomButton(props) {
  const { onClick, children, disabled = false } = props;

  return (
    <Button
      disabled={disabled}
      type='submit'
      variant='contained'
      onClick={onClick}
      sx={{ width: '100%', borderRadius: 1, mt: 2, textTransform: 'none' }}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
