import { createSlice } from '@reduxjs/toolkit';

const initialState = { clientId: null, appName: null, domainName: null };

const { reducer, actions } = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientDetails(state, action) {
      state.clientId = action.payload.clientId;
      state.appName = action.payload.appName;
      state.domainName = action.payload.domainName;
      state.signInUrl = action.payload.signInUrl;
      state.signOutUrl = action.payload.signOutUrl;
    },
  },
});

const { setClientDetails } = actions;
export { setClientDetails };
export default reducer;
