const LOG_IN = 'login';
const SIGN_UP = 'signup';
const FORGOT_PASSWORD = 'forgotPassword';
const CONFIRM_FORGOT_PASSWORD = 'confirmForgotPassword';
const CONFIRM_SIGN_UP = 'confirmSignUp';
const SET_PASSWORD = 'setPassword';

const ROUTES = [
  LOG_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  CONFIRM_SIGN_UP,
  SET_PASSWORD,
];

export {
  LOG_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  CONFIRM_SIGN_UP,
  SET_PASSWORD,
  ROUTES,
};
