import React from 'react';

import ListItem from '@mui/material/ListItem';

import CustomTextField from './CustomTextField';

function CustomListItemTextField(props) {
  const {
    id,
    label,
    value,
    onChange,
    icon = null,
    autoComplete = null,
    type = 'text',
  } = props;

  return (
    <ListItem>
      <CustomTextField
        id={id}
        label={label}
        value={value}
        onChange={onChange}
        icon={icon}
        autoComplete={autoComplete}
        type={type}
      />
    </ListItem>
  );
}

export default CustomListItemTextField;
