import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';

import { CLIENTS } from '../../constants/clients';
import { setClientDetails } from '../../store/client';
import CustomLink from '../../components/CustomLink';
import AdminFab from '../../components/AdminFab';
import { setMessage } from '../../store/settings';

const Root = styled('div')(() => {
  return {
    display: 'flex',
    height: '100%',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  };
});

function Layout() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const clientId = searchParams.get('client_id');
    const client = CLIENTS[clientId];
    if (client) {
      dispatch(setClientDetails({ ...client, clientId }));
    } else {
      dispatch(setMessage({ type: 'error', text: 'invalid client_id' }));
    }
  }, [searchParams, dispatch]);

  const handleVeregyClick = (e) => {
    e.preventDefault();
    window.location.assign('https://veregy.com/');
  };

  const handleHelpClick = (e) => {
    e.preventDefault();
    window.location.assign('https://support.veregy.com');
  };

  return (
    <Root>
      <CssBaseline />
      <Box
        component='img'
        sx={{
          height: 55,
          position: 'fixed',
          top: 50,
          boxShadow: 'none',
        }}
        alt='Orchestrate by Veregy'
        src='/orchestrate-logo-white.png'
        title='Orchestrate'
      />
      <Outlet />
      <Stack
        direction='row'
        justifyContent='space-between'
        spacing={4}
        sx={{ p: 1, maxWidth: 425, width: '100%' }}
      >
        <CustomLink onClick={handleVeregyClick}>© Veregy - 2024</CustomLink>
        <CustomLink onClick={handleHelpClick}>Help</CustomLink>
        <CustomLink>Terms</CustomLink>
        <CustomLink>Privacy</CustomLink>
      </Stack>
      <AdminFab />
    </Root>
  );
}

export default Layout;
