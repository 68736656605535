import { veregy } from './veregy';

export const muiTheme = {
  palette: {
    background: { default: veregy.colors.blue },
    primary: {
      main: veregy.colors.blue,
    },
    secondary: {
      main: veregy.colors.green,
    },
    warning: {
      main: veregy.colors.orange,
    },
    error: {
      main: veregy.colors.red,
    },
    text: {
      black: veregy.colors.darkGrey,
      grey: veregy.colors.grey,
    },
    skeleton: veregy.colors.lightgrey,
  },
  typography: {
    fontFamily: veregy.font.family,
  },
  viewWidth: 500,
  veregy,
};
