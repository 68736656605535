import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { init } from '@sentry/react';

import _package from '../../package.json';
import {
  LOG_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  CONFIRM_SIGN_UP,
  SET_PASSWORD,
} from '../constants/routes';
import Layout from './Layout';
import LogIn from './Layout/LogIn';
import SignUp from './Layout/SignUp';
import ForgotPassword from './Layout/ForgotPassword';
import ConfirmSignUp from './Layout/ConfirmSignUp';
import ConfirmForgotPassword from './Layout/ConfirmForgotPassword';
import SetPassword from './Layout/SetPassword';

if (['staging', 'prod', 'production'].includes(process.env.REACT_APP_ENV)) {
  init({
    dsn: 'https://535ca0648f6fe74026d3c0a58f5b2fd4@o240785.ingest.us.sentry.io/4507170705768448',
    environment: process.env.REACT_APP_ENV,
    release: _package.version,
  });
}

function AppRoutes() {
  const location = useLocation();
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    let _pageName = 'Orchestrate - Authentication';
    switch (location.pathname) {
      case `/${LOG_IN}`:
        _pageName = 'Orchestrate - Log In';
        break;

      case `/${SIGN_UP}`:
        _pageName = 'Orchestrate - Sign Up';
        break;

      case `/${FORGOT_PASSWORD}`:
        _pageName = 'Orchestrate - Forgot Password';
        break;

      case `/${SET_PASSWORD}`:
        _pageName = 'Orchestrate - Set Password';
        break;

      case `/${CONFIRM_FORGOT_PASSWORD}`:
      case `/${CONFIRM_SIGN_UP}`:
        _pageName = 'Orchestrate - Confirm';
        break;

      default:
        break;
    }

    setPageName(_pageName);
  }, [location.pathname]);

  return (
    <div className='App'>
      <title>{pageName}</title>
      <Routes>
        <Route path='/*' element={<Layout />}>
          <Route path={LOG_IN} element={<LogIn />} />
          <Route path={SIGN_UP} element={<SignUp />} />
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route
            path={CONFIRM_FORGOT_PASSWORD}
            element={<ConfirmForgotPassword />}
          />
          <Route path={CONFIRM_SIGN_UP} element={<ConfirmSignUp />} />
          <Route path={SET_PASSWORD} element={<SetPassword />} />
          <Route path='*' element={<Navigate to={`/${LOG_IN}`} />} />
        </Route>
      </Routes>
    </div>
  );
}

export default AppRoutes;
