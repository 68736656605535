import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function LoadingBar() {
  const { loading } = useSelector((state) => state.settings);

  if (!loading) {
    return <Box sx={(theme) => ({ width: theme.viewWidth, height: '4px' })} />;
  }

  return (
    <Box sx={(theme) => ({ width: theme.viewWidth })}>
      <LinearProgress />
    </Box>
  );
}

export default LoadingBar;
