import React from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@emotion/react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { authWithGoogle } from '../helpers/auth';

function GoogleButton(props) {
  const { action } = props;
  const theme = useTheme();
  const client = useSelector((state) => state.client);

  const handleGoogleButtonClick = async (e) => {
    e.preventDefault();
    try {
      await authWithGoogle(client);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <Divider variant='middle' sx={{ mt: 2 }}>
        <Typography variant='body2' sx={{ color: theme.palette.text.grey }}>
          or {action} using
        </Typography>
      </Divider>
      <ListItem sx={{ mt: 1, justifyContent: 'center' }}>
        <Button
          sx={{ width: '45%', borderRadius: 1, textTransform: 'none' }}
          variant='outlined'
          startIcon={
            <Icon sx={{ display: 'flex', width: '100%', height: '100%' }}>
              <img
                src='/google_logo.svg'
                alt='google'
                style={{ height: '100%' }}
              />
            </Icon>
          }
          onClick={handleGoogleButtonClick}
        >
          Google
        </Button>
      </ListItem>
    </>
  );
}

export default GoogleButton;
