import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';

import { respondToAuthChallenge } from '../../../helpers/cognito';
import { setMessage, setLoading } from '../../../store/settings';
import CustomCardContent from '../../../components/CustomCardContent';
import CustomListItemText from '../../../components/CustomListItemText';
import CustomListItemTextField from '../../../components/CustomListItemTextField';
import CustomListItemButton from '../../../components/CustomListItemButton';
import MessageBox from '../../../components/MessageBox';

function SetPassword() {
  const location = useLocation();
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));

    if (password !== confirmPassword) {
      dispatch(setMessage({ type: 'error', text: "password don't match" }));
      dispatch(setLoading(false));
      return;
    }

    try {
      const { refreshToken } = await respondToAuthChallenge(
        location.state.ChallengeName,
        {
          USERNAME: location.state.ChallengeParameters.USER_ID_FOR_SRP,
          NEW_PASSWORD: password,
        },
        location.state.Session,
        client
      );
      window.location.assign(
        `${client.signInUrl}?refreshToken=${refreshToken}`
      );
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <CustomCardContent title='Set Password'>
      <MessageBox />
      <form onSubmit={handleSubmit}>
        <List>
          <CustomListItemText>
            Enter a valid password to finish setting up your account.
          </CustomListItemText>
          <CustomListItemTextField
            id='new-password'
            label='New Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            icon={
              <InputAdornment
                position='start'
                onClick={() => setHidePassword((h) => !h)}
              >
                {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </InputAdornment>
            }
            type={hidePassword ? 'password' : 'text'}
          />
          <CustomListItemTextField
            id='confirm-password'
            label='Re-enter Password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            icon={
              <InputAdornment
                position='start'
                onClick={() => setHidePassword((h) => !h)}
              >
                {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </InputAdornment>
            }
            type={hidePassword ? 'password' : 'text'}
          />
          <CustomListItemButton onClick={handleSubmit}>
            Set Password
          </CustomListItemButton>
        </List>
      </form>
    </CustomCardContent>
  );
}

export default SetPassword;
