import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import LoadingBar from './LoadingBar';

function CustomCardContent(props) {
  const { title, children } = props;

  return (
    <Card raised sx={(theme) => ({ width: '100%', maxWidth: theme.viewWidth })}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          mt: '6px',
          ml: 1,
          mb: '6px',
        }}
      >
        <Box
          component='img'
          sx={{ height: 22, width: 22, mr: 1 }}
          alt='Veregy Gray Chevrons'
          src='/Chevron Dark Gray.png'
          title='Veregy'
        />
        <Typography align='left' color={(theme) => theme.palette.text.black}>
          {title}
        </Typography>
      </Box>
      <Divider />
      <LoadingBar />
      <CardContent sx={{ pb: '8px !important' }}>{children}</CardContent>
    </Card>
  );
}

export default CustomCardContent;
