export const veregy = {
  colors: {
    blue: 'rgba(25, 94, 136, 1)',
    lightblue: 'rgba(147, 183, 190, 1)',
    green: 'rgba(115, 176, 67, 1)',
    darkGrey: 'rgba(51, 51, 51, 1)',
    grey: 'rgba(92, 92, 92, 1)',
    grey50: 'rgba(92, 92, 92, .5)',
    lightgrey: 'rgba(180, 180, 180, 1)',
    lightgrey50: 'rgba(180, 180, 180, .5)',
    orange: 'rgba(233, 180, 75, 1)',
    purple: 'rgba(85, 79, 125, 1)',
    yellow: 'rgba(255, 230, 66, 1)',
    red: 'rgba(214, 73, 51, 1)',
    background: 'rgba(251,251,251,1)',
  },
  font: {
    family: "'Arial', 'Helvetica', 'sans-serif'",
    h1: {
      size: {
        d: '56px',
        t: '46px',
        m: '36px',
      },
      spacing: '2px',
      lineHeight: '1.4em',
    },
    h2: {
      size: {
        d: '40px',
        t: '32px',
        m: '24px',
      },
      spacing: '2px',
      lineHeight: '1.4em',
    },
    h3: {
      size: {
        d: '24px',
        t: '22px',
        m: '20px',
      },
      spacing: '2px',
      lineHeight: '1.4em',
    },
    text: {
      size: {
        d: '16px',
        t: '15px',
        m: '14px',
      },
      spacing: '0px',
      lineHeight: '1.6em',
    },
  },
};
