import { CognitoJwtVerifier } from 'aws-jwt-verify';
import { getEnvUserPoolClients } from '../constants/clients';

const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const APP_ID = 'orchestrate';
const REFRESH_TOKEN = 'RefreshToken';
const ACCESS_TOKEN = 'AccessToken';
const ID_TOKEN = 'IdToken';
const EXPR_TIME = 'ExprTime';

const getTokens = (clientId) => {
  return {
    refreshToken: localStorage.getItem(
      `${APP_ID}.${clientId}.${REFRESH_TOKEN}`
    ),
    accessToken: localStorage.getItem(`${APP_ID}.${clientId}.${ACCESS_TOKEN}`),
    idToken: localStorage.getItem(`${APP_ID}.${clientId}.${ID_TOKEN}`),
    exprTime: localStorage.getItem(`${APP_ID}.${clientId}.${EXPR_TIME}`),
  };
};

const setTokens = (payload, clientId) => {
  if ('ExpiresIn' in payload) {
    const exprTime =
      Math.round(new Date().getTime() / 1000) + payload.ExpiresIn;
    localStorage.setItem(`${APP_ID}.${clientId}.${EXPR_TIME}`, exprTime);
  }
  for (const token of [ACCESS_TOKEN, ID_TOKEN, REFRESH_TOKEN]) {
    localStorage.setItem(`${APP_ID}.${clientId}.${token}`, payload[token]);
  }
};

const removeTokens = (clientId) => {
  for (const token of [ACCESS_TOKEN, ID_TOKEN, REFRESH_TOKEN, EXPR_TIME]) {
    localStorage.removeItem(`${APP_ID}.${clientId}.${token}`);
  }
};

const verifyToken = async (token, tokenUse, clientId) => {
  if (clientId === 'admin') {
    clientId = Object.keys(getEnvUserPoolClients())[0];
  }

  const verifier = CognitoJwtVerifier.create({
    userPoolId: USER_POOL_ID,
    tokenUse,
    clientId,
  });

  try {
    return verifier.verify(token);
  } catch {
    console.error('Token not valid!');
  }
};

export { setTokens, getTokens, verifyToken, removeTokens };
