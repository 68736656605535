import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@emotion/react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function MessageBox() {
  const theme = useTheme();
  const { type = 'error', text } = useSelector(
    (state) => state.settings.message
  );

  const GREEN = alpha(theme.veregy.colors.green, 0.95);
  const RED = alpha(theme.veregy.colors.red, 0.95);

  const [bgColor, setBgColor] = useState(RED);

  useEffect(() => {
    if (type === 'success') setBgColor(GREEN);
    else setBgColor(RED);
  }, [type, GREEN, RED]);

  if (!text) return null;
  return (
    <Box
      sx={{
        ml: 1,
        mr: 1,
        borderRadius: 1,
        backgroundColor: bgColor,
      }}
    >
      <Typography align='center' color={theme.palette.common.white}>
        {text}
      </Typography>
    </Box>
  );
}

export default MessageBox;
