import React from 'react';

import TextField from '@mui/material/TextField';
import { useTheme } from '@emotion/react';

function CustomTextField(props) {
  const {
    id,
    label,
    value,
    onChange,
    icon = null,
    autoComplete = null,
    type = 'text',
  } = props;
  const theme = useTheme();

  return (
    <TextField
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      autoComplete={autoComplete}
      variant='standard'
      sx={{ width: '100%' }}
      type={type}
      InputProps={{
        startAdornment: icon,
        sx: { color: theme.palette.text.black },
      }}
    />
  );
}

export default CustomTextField;
