import {
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  ConfirmSignUpCommand,
  ForgotPasswordCommand,
  InitiateAuthCommand,
  SignUpCommand,
  ResendConfirmationCodeCommand,
  RespondToAuthChallengeCommand,
} from '@aws-sdk/client-cognito-identity-provider';

import { CLIENTS } from '../constants/clients';
import { setTokens } from './tokens';

const REGION = process.env.REACT_APP_REGION;

class CognitoIdpSdkClient {
  constructor() {
    this.client = new CognitoIdentityProviderClient({ region: REGION });
  }
  async execute(Command, input) {
    return await this.client.send(new Command(input));
  }
}

const refreshTokens = async (refreshToken, clientId) => {
  const sdkClient = new CognitoIdpSdkClient();
  const client = CLIENTS[clientId];

  const response = await sdkClient.execute(InitiateAuthCommand, {
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
    ClientMetadata: { app: client.appName },
    ClientId: clientId,
  });

  const authResult = response.AuthenticationResult;
  setTokens(authResult);
  return { accessToken: authResult.AccessToken, idToken: authResult.IdToken };
};

const initAuth = async (username, password, clientId, appName) => {
  const response = await new CognitoIdpSdkClient().execute(
    InitiateAuthCommand,
    {
      AuthFlow: 'USER_PASSWORD_AUTH',
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
      ClientMetadata: { app: appName },
      ClientId: clientId,
    }
  );
  return { ...response, clientId };
};

const signUp = async (username, password, name, client) => {
  const sdkClient = new CognitoIdpSdkClient();
  const response = await sdkClient.execute(SignUpCommand, {
    Username: username,
    Password: password,
    ClientId: client.clientId,
    ClientMetadata: { app: client.appName },
    UserAttributes: [{ Name: 'name', Value: name }],
  });
  return response.CodeDeliveryDetails;
};

const resendConfirmationCode = async (username, client) => {
  const sdkClient = new CognitoIdpSdkClient();
  const response = await sdkClient.execute(ResendConfirmationCodeCommand, {
    ClientId: client.clientId,
    Username: username,
    ClientMetadata: { app: client.appName },
  });
  return response.CodeDeliveryDetails;
};

const confirmSignUp = async (username, code, client) => {
  const sdkClient = new CognitoIdpSdkClient();
  const response = await sdkClient.execute(ConfirmSignUpCommand, {
    Username: username,
    ConfirmationCode: code,
    ClientId: client.clientId,
    ClientMetadata: { app: client.appName },
  });
  return response;
};

const forgotPassword = async (username, client) => {
  const sdkClient = new CognitoIdpSdkClient();
  const response = await sdkClient.execute(ForgotPasswordCommand, {
    Username: username,
    ClientId: client.clientId,
    ClientMetadata: { app: client.appName },
  });
  return response.CodeDeliveryDetails;
};

const confirmForgotPassword = async (username, password, code, client) => {
  const sdkClient = new CognitoIdpSdkClient();
  const response = await sdkClient.execute(ConfirmForgotPasswordCommand, {
    Username: username,
    Password: password,
    ConfirmationCode: code,
    ClientId: client.clientId,
    ClientMetadata: { app: client.appName },
  });
  return response;
};

const respondToAuthChallenge = async (
  challengeName,
  challengeResponses,
  session,
  client
) => {
  const sdkClient = new CognitoIdpSdkClient();
  const response = await sdkClient.execute(RespondToAuthChallengeCommand, {
    ChallengeName: challengeName,
    ChallengeResponses: challengeResponses,
    Session: session,
    ClientMetadata: { app: client.appName },
    ClientId: client.clientId,
  });

  const authResult = response.AuthenticationResult;
  setTokens(authResult, client.clientId);
  return {
    refreshToken: authResult.RefreshToken,
    accessToken: authResult.AccessToken,
    idToken: authResult.IdToken,
  };
};

export {
  refreshTokens,
  initAuth,
  forgotPassword,
  signUp,
  resendConfirmationCode,
  confirmSignUp,
  confirmForgotPassword,
  respondToAuthChallenge,
};
