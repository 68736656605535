import React from 'react';

import ListItem from '@mui/material/ListItem';
import CustomButton from './CustomButton';

function CustomListItemButton(props) {
  const { handleClick, children } = props;

  return (
    <ListItem>
      <CustomButton onClick={handleClick}>{children}</CustomButton>
    </ListItem>
  );
}

export default CustomListItemButton;
