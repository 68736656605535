import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  MessageBox: '',
  message: {
    type: '',
    text: '',
  },
};

const { reducer, actions } = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      state.message.type = action.payload.type;
      state.message.text = action.payload.text;
    },
  },
});

const { setLoading, setMessage } = actions;
export { setLoading, setMessage };
export default reducer;
