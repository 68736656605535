import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { LOG_IN } from '../../../constants/routes';
import {
  confirmSignUp,
  resendConfirmationCode,
} from '../../../helpers/cognito';
import { setLoading, setMessage } from '../../../store/settings';
import CustomButton from '../../../components/CustomButton';
import CustomCardContent from '../../../components/CustomCardContent';
import CustomListItemText from '../../../components/CustomListItemText';
import CustomListItemTextField from '../../../components/CustomListItemTextField';
import MessageBox from '../../../components/MessageBox';
import TextWithLink from '../../../components/TextWithLink';

function ConfirmSignUp() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const client = useSelector((state) => state.client);

  const [code, setCode] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    const _username = searchParams.get('username');
    if (_username) {
      setUsername(_username);
    } else {
      setUsername('');
    }
  }, [searchParams]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setMessage({ type: '', text: '' }));

    try {
      await confirmSignUp(username, code, client);
      dispatch(
        setMessage({ type: 'success', text: `Account confirmed: ${username}` })
      );
      dispatch(push(`${LOG_IN}?client_id=${client.clientId}`));
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSendNewCode = async (e) => {
    e.preventDefault();
    try {
      await resendConfirmationCode(username, client);
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <CustomCardContent title='Confirm Sign Up'>
      <MessageBox />
      <form onSubmit={handleSubmit}>
        <List>
          <CustomListItemText>
            We have sent a code by email to <strong>{username}</strong>.<br />{' '}
            Enter it below to confirm your account.
          </CustomListItemText>
          <CustomListItemTextField
            id='code'
            label='Code'
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <ListItem sx={{ flexDirection: 'column', alignItems: 'end' }}>
            <CustomButton onClick={handleSubmit}>Confirm Account</CustomButton>
            <TextWithLink
              text={`Didn't receive a code?`}
              linkText='Send a new code'
              onClick={handleSendNewCode}
            />
          </ListItem>
        </List>
      </form>
    </CustomCardContent>
  );
}

export default ConfirmSignUp;
